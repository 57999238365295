/* eslint-disable */
// prettier-ignore
var captions = {
  en: {
    title: 'Oops, We no longer support this browser',
    message:
      'Please upgrade to a modern browser. Click on one of these icons to upgrade to the latest version of the target browser. We recommend Chrome, but any choice will work.',
    button: 'Continue anyway',
  },
  cs: {
    title: 'Jejda, tento prohlížeč již nepodporujeme',
    message:
      'Proveď upgrade na moderní prohlížeč. Kliknutím na jednu z těchto ikon provedeš upgrade na nejnovější verzi cílového prohlížeče. Doporučujeme Chrome, ale můžeš vybrat i jiný.',
    button: 'Přesto pokračovat',
  },
  da: {
    title: 'Hovsa, vi understøtter ikke længere denne browser',
    message:
      'Opgradér venligst til en moderne browser. Klik på ét af disse ikoner, for at opgradere til den seneste version af den pågældende browser. Vi anbefaler Chrome, men et hvert valg vil fungere.',
    button: 'Fortsæt alligevel',
  },
  nl: {
    title: 'Oeps, we bieden geen ondersteuning meer voor deze webbrowser',
    message:
      'Upgrade alsjeblieft naar een moderne webbrowser. Klik op één van deze pictogrammen om te upgraden naar de nieuwste versie van die specifieke webbrowser. We raden Chrome aan, maar alle opties werken.',
    button: 'Toch doorgaan',
  },
  fr: {
    title: 'Oups, nous ne prenons plus en charge ce navigateur',
    message:
      "Veuillez mettre à jour votre navigateur. Cliquez sur l'une de ces icônes pour passer à la dernière version de votre navigateur. Nous recommandons Chrome, mais tout autre navigateur fonctionnera.",
    button: 'Continuer quand même',
  },
  de: {
    title: 'Hoppla, diesen Browser unterstützen wir nicht mehr',
    message:
      'Bitte hol dir einen modernen Browser. Klicke auf eines dieser Symbole, um auf die neueste Version des Zielbrowsers zu aktualisieren. Wir empfehlen Chrome, aber jede andere Wahl wird genauso funktionieren.',
    button: 'Trotzdem fortfahren',
  },
  hu: {
    title: 'Hoppá, ezt a böngészőt már nem támogatjuk',
    message:
      'Kérjük, váltson egy modern böngészőre. Kattintson az egyik ikonra a célböngésző legújabb verziójára való frissítéshez. Mi a Chrome-ot javasoljuk, de bármelyik választás működni fog.',
    button: 'Folytatás mindenképpen',
  },
  id: {
    title: 'Ups, Kami tidak lagi mendukung browser ini',
    message:
      'Tingkatkan ke browser modern. Klik salah satu ikon ini untuk meningkatkan ke versi terbaru dari browser target. Kami merekomendasikan Chrome, tetapi pilihan apa pun akan berhasil.',
    button: 'Tetap lanjutkan',
  },
  it: {
    title: 'Ops, non supportiamo più questo browser',
    message:
      'Ti invitiamo a passare a un browser aggiornato. Fai clic su una di queste icone per passare alla versione più recente del browser desiderato. Consigliamo Chrome, ma qualsiasi opzione andrà bene.',
    button: 'Continua comunque',
  },
  ja: {
    title: 'おっと、このブラウザーはサポートが終了しています',
    message:
      '最新のブラウザーに更新してください。アイコンのどれかをクリックすると、そのブラウザーの最新版に更新できます。Chromeがオススメですが、そのほかでも大丈夫です。',
    button: 'このまま続ける',
  },
  nb: {
    title: 'Opp sann – vi støtter ikke denne nettleseren lenger',
    message:
      'Vennligst oppgrader til en moderne nettleser. Klikk på et av disse ikonene for å oppgradere til nyeste versjon av den aktuelle nettleseren. Vi anbefaler Chrome, men alle valgene fungerer.',
    button: 'Fortsett likevel',
  },
  pl: {
    title: 'Ojej, już nie obsługujemy tej przeglądarki',
    message:
      'Zmień przeglądarkę na nową. Kliknij jedną z ikon, aby uaktualnić do najnowszej wersji wybranej przeglądarki. Zalecamy Chrome, ale każda z nich będzie działać.',
    button: 'Kontynuuj mimo to',
  },
  'pt-br': {
    title: 'Opa, nós não suportamos mais este navegador',
    message:
      'Atualize para um navegador moderno. Clique em um destes ícones para atualizar para a versão mais nova do navegador desejado. Recomendamos o Chrome, mas qualquer opção funcionará.',
    button: 'Continuar assim mesmo',
  },
  ro: {
    title: 'Hopa, acest browser nu mai este compatibil',
    message:
      'Te rugăm să faci upgrade la un browser modern. Fă clic pe una dintre aceste pictograme pentru a face upgrade la versiunea cea mai recentă a browserului vizat. Noi recomandăm Chrome, dar va funcționa orice alegi.',
    button: 'Continuă oricum',
  },
  ru: {
    title: 'К сожалению, мы больше не поддерживаем этот браузер.',
    message:
      'Пожалуйста, замените браузер на современный. Нажмите на одну из этих иконок для обновления до последней версии целевого браузера. Мы рекомендуем Chrome, но любой выбор подойдет.',
    button: 'Продолжить в любом случае',
  },
  sk: {
    title: 'Ups, tento prehliadač už nepodporujeme',
    message:
      'Aktualizujte, prosím, na moderný prehliadač. Kliknite na jednu z týchto ikon pre aktualizovanie na najnovšiu verziu Vami vybraného prehliadača. Odporúčame Vám Chrome, ale ktorákoľvek voľba bude spoľahlivo fungovať.',
    button: 'Pokračovať aj napriek tomu',
  },
  es: {
    title: 'Vaya, ya no somos compatibles con este navegador',
    message:
      'Actualízate a un navegador moderno. Haz clic en uno de estos iconos para actualizar el navegador escogido a su última versión. Recomendamos Chrome, pero cualquier otro funcionará.',
    button: 'Continuar de todas formas',
  },
  sv: {
    title: 'Oj, vi stöder inte längre den här webbläsaren',
    message:
      'Vänligen uppgradera till en modern webbläsare. Klicka på en av ikonerna för att uppgradera till senaste versionen av önskad webbläsare. Vi rekommenderar Chrome, men samtliga fungerar.',
    button: 'Fortsätt ändå',
  },
  'zh-cn': {
    title: '哎呀，我们不再支持此浏览器',
    message:
      '请升级到最新版的浏览器。点击其中一个图标以升级到目标浏览器的最新版本。我们建议使用 Chrome，但您可以随意选择。',
    button: '仍然继续',
  },
};

export default captions;
