/* eslint-disable */
// prettier-ignore
import bowser from 'yola-bowser';
import getCookie from './helpers/get-cookie';
import captions from './constants/captions';
import supportedBrowsers from './constants/supported-browsers';
import NOT_SUPPORTED_BROWSER_CLASSNAME from './constants/not-supported-classname';

var dialogUiMap = {
  title: 'ws-browser-support-dialog__title',
  message: 'ws-browser-support-dialog__message',
  button: 'ws-browser-support-dialog__button',
};

(function () {
  var isSupportedBrowser = bowser.isSatisfied(supportedBrowsers);
  var dialog = document.getElementById('ws-browser-support-dialog');

  if (isSupportedBrowser) {
    dialog.remove();
    return;
  }

  document.documentElement.classList.add(NOT_SUPPORTED_BROWSER_CLASSNAME);

  var currentLang = getCookie('current_lang');

  Object.keys(dialogUiMap).forEach(function (key) {
    var elementId = dialogUiMap[key];
    var element = document.getElementById(elementId);
    var langCaptions = captions[currentLang] || captions.en;
    element.innerHTML = langCaptions[key];
  });

  document.getElementById(dialogUiMap.button).addEventListener('click', function (e) {
    e.preventDefault();
    dialog.remove();
  });

  setTimeout(function () {
    dialog.removeAttribute('style');
  }, 3000);
})();
